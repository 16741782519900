import { compose } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';
import AdBillboard from 'core/components/Ad/Billboard';

import Native1Styles from './natives/Native1';


export const Billboard = bindProps({
  name: 'Billboard',
  blockId: '432500906',
  height: 250,
  options: {
    'p1': 'btqzl',
    'p2': 'y',
    'pct': 'c',
  },
})(AdBillboard);

export const AdPlace2 = bindProps({
  name: '240x400',
  width: 300,
  height: 600,
  blockId: '432500902',
  stickyTemp: 3,
  options: {
    'p1': 'btqzm',
    'p2': 'emhk',
    'pct': 'c',
  },
})(Ad);

export const AdPlace3 = bindProps({
  name: '240x400_2nd',
  width: 300,
  height: 600,
  lazy: true,
  blockId: '432500882',
  options: {
    'p1': 'btqzn',
    'p2': 'ewqs',
    'pct': 'a',
  },
})(Ad);

export const SuperFooter = bindProps({
  name: '100%x240',
  width: 600,
  height: 240,
  lazy: true,
  blockId: '432500876',
  options: {
    'p1': 'btqzo',
    'p2': 'fcuz',
    'pct': 'c',
  },
})(Ad);

export const Parallax = bindProps({
  name: 'Parallax',
  width: 1200,
  height: 250,
  lazy: true,
  blockId: '445548674',
  options: {
    'p1': 'bwujw',
    'p2': 'fhoe',
    'pct': 'a',
  },
})(Ad);

export const Native1 = compose(
  bindProps({
    name: 'Native1',
    blockId: '433694186',
    options: {
      'p1': 'bunzf',
      'p2': 'fhzr',
      'pct': 'a',
    },
  }),
  Native1Styles,
)(Ad);
