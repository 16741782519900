import React from 'react';

import Gallery from 'core/components/Gallery';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';

export default function PhotoGallery({ block }) {
  return (
    <Gallery photoGallery={block} />
  );
}

PhotoGallery.propTypes = {
  block: modelPropTypes(photoGalleryAttributes).isRequired,
};
