import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { filterRequiredParams } from 'core/utils/api';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import Feed from 'core/components/Feed';
import Mjolnir from 'core/components/Mjolnir';
import AdWrapper from 'core/components/Ad/AdWrapper';
import PageLoader from 'core/components/Loader/PageLoader';

import {
  HORIZONTAL,
  HORIZONTAL_MOBILE,
} from 'site/components/Indents';
import { IndentWrap } from 'site/components/Wrappers';
import BreakpointSwitcher from 'site/components/BreakpointSwitcher';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import Card5, { Card5S } from 'site/cards/Card5';
import CompareCard from 'site/cards/CompareCard';

import {
  SuperFooter,
  Native1,
} from 'site/components/Ads/desktop';

import {
  Listing1,
} from 'site/components/Ads/mobile';

import GameCompareRubric from './GameCompareRubric';


function TopicsContent(props) {
  const {
    topics,
    isLoading,
    isGameComparePage,
    isDesktop,
  } = props;

  if (isLoading) return <PageLoader />;
  if (!topics?.length) return null;

  if (isGameComparePage) {
    return <GameCompareRubric topics={topics} isDesktop={isDesktop} />;
  }

  const spacing = isDesktop ? HORIZONTAL : HORIZONTAL_MOBILE;
  const sideSpacings = {
    right: isDesktop ? 0 : spacing,
    left: isDesktop ? 0 : spacing,
  };

  const first = topics.slice(0, 2);
  const second = topics.slice(2, 5);
  const third = topics.slice(5, 8);
  const fourth = topics.slice(8, 11);
  const fifth = topics.slice(11, 21);

  return (
    <AdWrapper bottom={spacing}>
      {first.length > 0 && (
        <IndentWrap bottom={isDesktop ? spacing : 0}>
          <BreakpointSwitcher
            desktop={(
              <Feed
                content={first}
                card={Card4}
                interitemSpacing={28}
                columns={2}
                grid
              />
            )}
            mobile={(
              <Mjolnir
                content={first}
                card={Card3}
                heroCard={Card5S}
                cardsContainerPadding={20}
              />
            )}
          />
        </IndentWrap>
      )}
      {second.length > 0 && (
        <Fragment>
          <IndentWrap bottom={spacing} {...sideSpacings}>
            <Feed
              content={second}
              card={isDesktop ? Card5 : Card3}
              interitemSpacing={spacing}
              columns={3}
              grid
              cardsReplacement={{
                2: replaceCardWithAd(Native1, isDesktop),
              }}
            />
          </IndentWrap>
          <BreakpointSwitcher
            mobile={<Listing1 />}
          />
        </Fragment>
      )}
      {third.length > 0 && (
        <Fragment>
          <IndentWrap bottom={spacing} {...sideSpacings}>
            <Feed
              content={third}
              card={isDesktop ? Card2 : Card3}
              interitemSpacing={spacing}
              columns={3}
              grid
            />
          </IndentWrap>
          <BreakpointSwitcher
            desktop={<SuperFooter />}
          />
        </Fragment>
      )}
      {fourth.length > 0 && (
        <Fragment>
          <IndentWrap bottom={spacing} {...sideSpacings}>
            <Feed
              content={fourth}
              card={isDesktop ? Card2 : Card3}
              interitemSpacing={spacing}
              columns={3}
              grid
            />
          </IndentWrap>
        </Fragment>
      )}
      {fifth.length > 0 && (
        <IndentWrap bottom={spacing} {...sideSpacings}>
          <Feed
            content={fifth}
            card={isDesktop ? Card2 : Card3}
            interitemSpacing={spacing}
            columns={3}
            grid
          />
        </IndentWrap>
      )}
    </AdWrapper>
  );
}

TopicsContent.propTypes = {
  topics: PropTypes.array,
  isLoading: PropTypes.bool,
  isGameComparePage: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

const Topics = withBreakpoint(TopicsContent);


Topics.fields = filterRequiredParams([Card2, Card4, Card5, CompareCard], 'fields');
Topics.include = filterRequiredParams([Card2, Card4, Card5, CompareCard], 'include');

export default Topics;
