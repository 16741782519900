import { Mobile } from 'core/components/breakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';

import {
  Footer as BanFooter,
} from 'site/components/Ads/mobile';

export default function AdFooter() {
  return (
    <AdWrapper top={40}>
      <Mobile>
        <BanFooter />
      </Mobile>
    </AdWrapper>
  );
}
