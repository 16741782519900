import { isSameDay } from 'core/libs/date-fns';
import resolveRelationships from 'core/utils/relationships';
import { topicDateFormat, dateFormat } from 'core/utils/dates';


const relationships = resolveRelationships(['content'], {});

export function capitalize(str) {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function buildRubricUrl(slug, rootSlug) {
  if ((slug === rootSlug) || !rootSlug) {
    return `/${slug}`;
  }
  return `/${rootSlug}/${slug}`;
}

export function cardDateFormat(published) {
  /**
   * В отличии от topicDateFormat, в карточка не нужно выводить 'Сегодня в'
   */
  const date = new Date(published);

  if (isNaN(date)) return null;

  if (isSameDay(Date.now(), date)) {
    return dateFormat(published, 'HH:mm');
  }

  return topicDateFormat(published);
}

/**
 * Возвращает реальный тип топика, учитываю реалии Passion
 *
 * На данный момент особенность заключается в том, что новость, у которой
 * первый виджет фотогалерея, должен быть фотогалереей
 */
export function getRealType(content) {
  const {
    attributes: {
      topic_type: topicType,
    },
  } = content;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  const isNews = topicType === 'news';
  const isGalleryFirst = widgets && widgets[0]?.type === 'photoGallery';

  return isNews && isGalleryFirst ? 'news-gallery' : topicType;
}

export function getGroups(rubrics, allTopics) {
  const children = rubrics?.meta.ancestry[1].children;

  if (!children) return [];

  const sortedRubrics = children.sort((a, b) => {
    const ttlA = a.attributes.title;
    const ttlB = b.attributes.title;
    let longTtlSort = 0;

    if (ttlA.length > 1) {
      longTtlSort = 1;
    } else if (ttlB.length > 1) {
      longTtlSort = -1;
    }

    return longTtlSort || ttlA.localeCompare(ttlB);
  });

  return sortedRubrics
    .map(rubric => {
      const id = rubric.id;
      rubric.topics = allTopics.filter(topic => {
        return topic?.relationships?.rubric.data?.id === id;
      });
      return rubric;
    })
    .filter(item => item.topics.length > 0);
}
