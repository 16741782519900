import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Breadcrumbs from 'site/components/Breadcrumbs';

import { IndentWrap } from 'site/components/Wrappers';
import Headline from 'site/components/Headline';

function RubricHeader(props) {
  const {
    rawRubric,
    title,
    isMobile,
  } = props;

  return (
    <IndentWrap left={isMobile ? 20 : 0} right={isMobile ? 20 : 0}>
      {rawRubric && (
        <IndentWrap bottom={20}>
          <Breadcrumbs rawRubric={rawRubric} />
        </IndentWrap>
      )}
      {!!title && <Headline>{title}</Headline>}
    </IndentWrap>
  );
}

RubricHeader.propTypes = {
  /** Контент рубрики, полученный из API */
  rawRubric: PropTypes.object,
  /** Заголовок рубрики */
  title: PropTypes.string.isRequired,
  /** @ignore */
  isMobile: PropTypes.bool,
};

const RubricHeaderWithHOCs = withBreakpoint(RubricHeader);
RubricHeaderWithHOCs.displayName = 'RubricHeader';

export default RubricHeaderWithHOCs;

export { RubricHeader as StorybookComponent };
