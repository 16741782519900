import PropTypes from 'prop-types';

import Gallery from 'core/components/Gallery';

import resolveRelationships from 'core/utils/relationships';

import { getRealType } from 'site/utils';

const relationships = resolveRelationships(['content'], {});

function TopicHeaderGallery({ content }) {
  const {
    photo_gallery: {
      data: realPhotoGallery,
    },
  } = content.relationships;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  const photoGallery = getRealType(content) === 'news-gallery'
    ? widgets[0]
    : realPhotoGallery;

  return photoGallery ? (
    <Gallery
      photoGallery={photoGallery}
      hashNavigation
      hashNavigationLink={content.attributes.link}
      adsToReload={[
        {
          name: 'Billboard',
          count: 2,
        },
        {
          name: '240x400',
          count: 2,
        },
        {
          name: 'TopBanner',
          count: 2,
        },
      ]}
    />
  ) : null;
}

TopicHeaderGallery.propTypes = {
  content: PropTypes.object,
};

export default TopicHeaderGallery;
